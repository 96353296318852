@use "styles" as *;

.root {
  width: 100%;
  overflow: hidden;
  @include for-size(desktop) {
    @include flex(center, flex-start, column);
  }
  @include for-size(phone) {
    @include flex(flex-start, flex-start, column);
  }
  .slider {
    width: 100%;
    overflow: hidden;
    .slides {
      @include flex(flex-start, flex-start, row);
      @include transition(0.5s ease-in-out);
      .item {
        width: 100%;
        height: 200px;
        padding: 16px 0;
        flex-shrink: 0;
        @include flex(center, center, row);
        .slide {
          @include border-radius(10px);
        }
      }
    }
  }
  .thumbnailsContainer {
    @include flex(flex-start, flex-start, row);
    .thumbnails {
      @include flex(flex-start, flex-start, row);
      gap: 10px;
      overflow-x: auto;
      white-space: nowrap;
      @include scrollbars(0);
      .thumbnailsItem {
        @include flex(center, center, row);
        padding: 4px;
        border: 1px solid $grey2;
        @include border-radius(6px);
        flex-shrink: 0;
        &.active {
          border: 1.5px solid $brandColor4;
        }
        > img {
          @include border-radius(8px);
        }
      }
    }
    .arrow {
      background: none;
      border: 1px solid $brandColor6;
      z-index: 3;
      font-size: 2em;
      padding: 4px;
      @include border-radius(4px);
      cursor: pointer;
      user-select: none;
      &.arrowLeft,
      &.arrowRight {
        margin: 5px;
      }
    }
  }
  .columnThumbnail {
    @include flex(flex-start, flex-start, column);
  }
  .rowThumbnail {
    @include flex(flex-start, flex-start, row);
  }
}

.left {
  flex-direction: row-reverse;
  .thumbnails {
    width: 60px;
    flex-wrap: wrap;
    height: 300px;
    overflow-y: auto;
    white-space: nowrap;
    margin: 10px 0px;
  }
}
.right {
  flex-direction: row;
  .thumbnails {
    width: 60px;
    flex-wrap: wrap;
    height: 300px;
    overflow-y: auto;
    white-space: nowrap;
    margin: 10px 0px;
  }
}
.top .slider {
  order: 2;
}
.bottom {
  order: 2;
}
