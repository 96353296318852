@use "styles" as *;

.imagePreviewContainer {
  position: relative;
  height: 100%;
}

.expressLogo {
  text-align: right;
  margin-bottom: 20px;
  min-height: 11px;
}
///
.slider {
  @include flex(center, flex-start, row);
  gap: 24px;
  overflow-x: auto;
  height: calc(100dvh - 128px);
  max-height: 500px;
  scroll-snap-type: x mandatory;
  .slide {
    text-align: center;
    scroll-snap-align: center;
    flex: none;
    flex: 0 0 100%;
    width: 100%;
    overflow: auto;
    text-align: center;
  }
}

.imageScale {
  overflow: hidden;
  height: 100%;
  max-height: inherit;
  .slide {
    height: 80dvh;
    max-height: 500px;
    width: 300px;
    overflow: scroll;
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
  }
  .img {
    height: 800px;
    width: 800px;
    max-height: initial;
    max-width: initial;
  }
}
