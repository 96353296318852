@import "./colors.scss";

$primaryColor: #02475b;
$secondaryColor: #fc9916;
$tertiaryColor: #07ae8b;
$black: #000000;
$white: #ffffff;
$aquaBlue: #0087ba;
$darkGrey: #080808;
$lightGrey: #f7f8f5;
$grey: #808080;
$darkRed: #890000;
$smaltBlue: #4e808d;
$red: #d0483d;
$green: #4caf50;
$raisinBlack: #212223;
$skeletonBg: #f4f5f6;
$greyTxt: #979797;
$aquaBlueLight: #007c9d;
$greyBorder: #d4d4d4;
$bodyBg: #ececec;
$darkGreen: #228a87;
$lightBlack: #121414;
$poppy: #f79520;

// Media Variable
$md: 1024px;
$sm: 768px;
$xs: 600px;

$font: var(--font-inter), sans-serif;
// New Design variable
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1276px,
);
$grid-columns: 12;
