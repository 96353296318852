@use "styles" as *;

.thumbnail {
  min-height: 60px;
  @include for-size(desktop) {
    align-self: flex-start;
    max-height: 340px;
    position: relative;
  }
  &.minHeightForThumbnail {
    @include for-size(desktop) {
      min-height: 325px;
    }
  }
  .keen-slider {
    overflow: visible;
  }
  .thumbnailCards {
    cursor: pointer;
    @include border-radius(8px);
    border: 1px solid $grey2;
    @include box-shadow(none);
    padding: 8px;
    > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      @include border-radius(8px);
      height: auto;
    }
    @include for-size(desktop) {
      font-size: 30px;
      min-height: 72px !important;
      min-width: 72px !important;
    }
    @include for-size(phone) {
      min-width: 60px !important;
      max-width: 60px !important;
      max-height: 60px;
    }
    @include flex(center, center, row);
    &.active {
      border: 2px dashed black;
    }
  }
}
.arrow {
  border-radius: 6px;
  border: 1px solid $brandColor6;
  z-index: 3;
  width: 32px;
  height: 32px;
  position: absolute;
  @include transform(rotate(90deg));
  fill: #1d1b95;
  cursor: pointer;
  @include border-radius(6px);
  padding: 4px;
  background-color: rgba($white, 0.5);
  @include flex(center, center, row);
  svg {
    width: 12px;
  }
  &.arrowShimmer {
    padding: 0;
    border-color: $grey2;
  }
}

.arrowLeft,
.arrowRight {
  left: 20px;
  background: $white;
  //width: 100%;
}
.arrowLeft {
  top: 0;
}

.arrowRight {
  bottom: 0;
}
.thumbnailOutOfStock {
  opacity: 0.5;
}
.thumbnailShimmer {
  > span {
    background: $white;
    border: 1px solid $grey4;
    &:first-child {
      border-color: #156f6c;
    }
  }
}
.thumbnailImage {
  color: $white;
  font-size: 5px;
}
