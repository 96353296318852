@use "./variables" as *;

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin flex($align: null, $justify: null, $direction: null) {
  display: flex;
  display: -webkit-flex;
  align-items: $align;
  -webkit-align-items: $align;
  justify-content: $justify;
  -webkit-justify-content: $justify;
  flex-direction: $direction;
  -webkit-flex-direction: $direction;
}

@mixin text-overflow($content) {
  text-overflow: $content;
  overflow: hidden;
  white-space: nowrap;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin font-weight($weight) {
  font-weight: normal;

  @if $weight == 500 {
    font-weight: 500;
  } @else if $weight == 600 {
    font-weight: 600;
  } @else if $weight == 700 {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }
}
//scrollbar mixin --*as
@mixin scrollbars($size, $foregroundColor: $grey4, $backgroundColor: $grey2) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foregroundColor;
    @include border-radius(25px);
  }

  &::-webkit-scrollbar-track {
    background: $backgroundColor;
  }
}

//// New Design mixin

/// common media breakpointn mixin
@mixin for-size($size) {
  @if $size == phone {
    @media (max-width: 767.9px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 991.9px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 768px) {
      @content;
    }
  }
}

@mixin container {
  width: 100%;
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 16px;
}
@mixin imageResponsive {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
