@use "styles" as *;

.viewAllText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #156f6c;
  cursor: pointer;
  @include for-size(desktop) {
    padding: 16px;
  }
  @include for-size(phone) {
    padding: 0;
  }
}
