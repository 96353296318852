@use "styles" as *;
.dot {
  border: none;
  width: 4px;
  height: 4px;
  background: $grey5;
  @include border-radius(10px);
  margin: 0 4px;
  cursor: pointer;
  @include for-size(phone) {
    pointer-events: none;
  }
}

.dot {
  &.active {
    background: $tertiaryColor;
    width: 12px;
    height: 4px;
  }
}

.dot:focus {
  outline: none;
}
