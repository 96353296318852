@use "styles" as *;

.ppWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
}
.productPopup {
  position: relative;
  width: 600px;
  height: 400px;
  background: #fff;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
}
.ppContent {
  height: calc(100dvh - 80px);
  padding: 10px;
  overflow: hidden;
  background: $white;
}
.ppHeader {
  @include flex(flex-start, flex-start, row);
  padding: 20px 16px;
  height: 80px;
  > span {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    max-height: 40px;
    @include line-clamp(2);
  }
}
.icon-ic_cross:before {
  content: "\e96f";
  color: #02475b;
}

.icon-medicine {
  span:nth-child(1) {
    &:before {
      content: "\ea28";
      color: rgb(0, 0, 0);
      opacity: 0.096;
    }
  }
  span:nth-child(2) {
    &:before {
      content: "\ea29";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(3) {
    &:before {
      content: "\ea2a";
      margin-left: -1.7431640625em;
      color: rgb(77, 208, 225);
    }
  }
  span:nth-child(4) {
    &:before {
      content: "\ea2b";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(5) {
    &:before {
      content: "\ea2c";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(6) {
    &:before {
      content: "\ea2d";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(7) {
    &:before {
      content: "\ea2e";
      margin-left: -1.7431640625em;
      color: rgb(77, 208, 225);
    }
  }
  span:nth-child(8) {
    &:before {
      content: "\ea2f";
      margin-left: -1.7431640625em;
      color: rgb(40, 187, 198);
    }
  }
  span:nth-child(9) {
    &:before {
      content: "\ea30";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(10) {
    &:before {
      content: "\ea31";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(11) {
    &:before {
      content: "\ea32";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(12) {
    &:before {
      content: "\ea33";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(13) {
    &:before {
      content: "\ea34";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(14) {
    &:before {
      content: "\ea35";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(15) {
    &:before {
      content: "\ea36";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(16) {
    &:before {
      content: "\ea37";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(17) {
    &:before {
      content: "\ea38";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(18) {
    &:before {
      content: "\ea39";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(19) {
    &:before {
      content: "\ea3a";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(20) {
    &:before {
      content: "\ea3b";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(21) {
    &:before {
      content: "\ea3c";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(22) {
    &:before {
      content: "\ea3d";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(23) {
    &:before {
      content: "\ea3e";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(24) {
    &:before {
      content: "\ea3f";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(25) {
    &:before {
      content: "\ea40";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(26) {
    &:before {
      content: "\ea41";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(27) {
    &:before {
      content: "\ea42";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(28) {
    &:before {
      content: "\ea43";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(29) {
    &:before {
      content: "\ea44";
      margin-left: -1.7431640625em;
      color: rgb(77, 208, 225);
    }
  }
  span:nth-child(30) {
    &:before {
      content: "\ea45";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(31) {
    &:before {
      content: "\ea46";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(32) {
    &:before {
      content: "\ea47";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(33) {
    &:before {
      content: "\ea48";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(34) {
    &:before {
      content: "\ea49";
      margin-left: -1.7431640625em;
      color: rgb(77, 208, 225);
    }
  }
  span:nth-child(35) {
    &:before {
      content: "\ea4a";
      margin-left: -1.7431640625em;
      color: rgb(209, 221, 232);
    }
  }
  span:nth-child(36) {
    &:before {
      content: "\ea4b";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(37) {
    &:before {
      content: "\ea4c";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(38) {
    &:before {
      content: "\ea4d";
      margin-left: -1.7431640625em;
      color: rgb(209, 221, 232);
    }
  }
  span:nth-child(39) {
    &:before {
      content: "\ea4e";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(40) {
    &:before {
      content: "\ea4f";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(41) {
    &:before {
      content: "\ea50";
      margin-left: -1.7431640625em;
      color: rgb(255, 255, 255);
    }
  }
  span:nth-child(42) {
    &:before {
      content: "\ea51";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(43) {
    &:before {
      content: "\ea52";
      margin-left: -1.7431640625em;
      color: rgb(231, 237, 244);
    }
  }
  span:nth-child(44) {
    &:before {
      content: "\ea53";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(45) {
    &:before {
      content: "\ea54";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
  span:nth-child(46) {
    &:before {
      content: "\ea55";
      margin-left: -1.7431640625em;
      color: rgb(35, 81, 150);
    }
  }
}
