@use "styles" as *;

$grey1: #fafbf9;
$grey2: #f1f1ef;
$grey3: #e7e7e5;
$grey4: #d9d9d7;
$grey5: #ccccc9;
$grey6: #bebebb;
$grey7: #989795;
$grey8: #71716e;
$grey9: #4b4a48;

$shadowSmall: 0px 2px 8px rgba($grey6, 0.6);
$shadowMedium: 0px 4px 16px rgba($grey6, 0.6);
$shadowLarge: 0px 8px 20px rgba($grey6, 0.6);
$shadowExtraLarge: 0px 12px 32px rgba($grey6, 0.6);

.sliderContainer {
  position: relative;
  margin-bottom: 56px;
  @include for-size(phone) {
    margin-bottom: 32px;
  }
}

.arrow {
  z-index: 3;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
  fill: #1d1b95;
  cursor: pointer;
  @include border-radius(6px);
  padding: 4px;
  background-color: rgba($white, 0.5);
  @include flex(center, center, row);
  svg {
    width: 12px;
  }
}

.arrowLeft {
  left: 5px;
}

.arrowRight {
  left: auto;
  right: 5px;
}

.loaded {
  overflow: auto;
  display: flex;
  scroll-snap-type: x mandatory;
  .numberslide1 {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
}
.shimmerWrap {
  display: flex;
  width: 97%;
  justify-content: space-between;
  div {
    border-radius: 10px;
    margin-bottom: 20px;
  }
  :last-child {
    margin-bottom: 0px;
  }
}
.customSliderRoot {
  position: relative;
  @include for-size(desktop) {
    margin: 0 -16px;
    padding: 0 16px;
  }
}
.arrow {
  @include flex(center, center, row);
  background: rgba($white, 1);
  @include border-radius(5px);
  filter: drop-shadow(0 2px 6px rgba(190, 190, 187, 0.6));
  @include transition(all ease-in-out 0.2s);
  height: 48px;
  width: 30px;
  &:hover {
    background: rgba($white, 1);
  }
}
.disableArrow {
  opacity: 0.8;
  cursor: not-allowed;
  background-color: #ececec !important;
}
.bannerStyle {
  @include for-size(desktop) {
    height: 200px; // added only first time then remove height
  }
  @include for-size(tablet) {
    height: auto;
  }
  @include for-size(phone) {
    min-height: 176px;
  }
}

.thinBannerStyle {
  @include for-size(desktop) {
    height: 200px; // added only first time then remove height
  }
  @include for-size(tablet) {
    height: auto;
  }
  @include for-size(phone) {
    min-height: 60px;
  }
}

.pdpBannerStyle {
  @include for-size(desktop) {
    height: 160px; // added only first time then remove height
  }
  @include for-size(tablet) {
    height: auto;
  }
  @include for-size(phone) {
    min-height: 150px;
  }
}
.siderRoot {
  @include for-size(desktop) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    > div {
      &:first-child {
        width: calc(100% - 112px);
        margin: 0 20px;
        padding: 0;
      }
      &:last-child {
        width: 72px;
      }
    }
  }
}
.verticalCarosuel {
  max-height: 300px;
  > div {
    max-height: 150px !important;
  }
}
.pdpProductImage {
  @include for-size(phone) {
    height: 200px;
    div,
    img {
      height: 100%;
    }
  }
}
.cardsShimmer {
  @include flex(flex-start, space-between, row);
  gap: 10px;
  overflow: hidden;
  width: 100%;
  > div {
    width: calc((100% / var(--carouselDynamicWidth)) - 6px);
    flex-shrink: 0;
    > a {
      width: 100%;
    }
  }
}
.headingTextSkeleton {
  width: 100%;
  @include for-size(desktop) {
    height: 56px;
    .headTextSkeleton {
      height: 29px;
    }
  }
  @include for-size(phone) {
    height: 35px;
    .headTextSkeleton {
      height: 19px;
    }
  }
}
.mwebCarauselHeight {
  @include for-size(phone) {
    min-height: 230px;
  }
}
